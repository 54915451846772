import React from "react";
import moment from "moment";
import { withRouter } from "react-router-dom";
import leftList from "./components/leftCard";
import RightList from "./components/rightList";
import "./index.less";

const Wrapper = Loader.loadBaseComponent("TwoColumnLayout");

@withRouter
@Decorator.businessProvider("tab")
class VehicleFileSnapshot extends React.Component {
  state = {
    snapshots: [],
    vehicleData: {},
    total: 0,
    searchData: {
      startTime: moment(
        moment()
          .subtract(1, "months")
          .format("YYYY-MM-DD 00:00:00")
      ).valueOf(),
      endTime: moment().valueOf(),
      vehiclePlateNum: "",
      limit: 200,
      offset: 0
    },
    loading: true
  };
  componentDidMount() {
    const { history } = this.props;
    let {searchData} = this.state
    const { id } = Utils.queryFormat(history.location.search);
    LM_DB.get("parameter", id).then(data => {
      this.setState(
        {
          vehicleData: { ...data.vehicleData },
          searchData: {
            ...searchData,
            vehiclePlateNum: data.vehicleData.vehiclePlateNum
          }
        },
        () => this.initSnapshot()
      );
    });
  }
  
  initSnapshot = () => {
    let {searchData} = this.state
    Service.community.queryVehicleRecords(searchData).then(res => {
      this.setState({ snapshots: res.list, total: res.total, loading: false });
    });
  };
  //跳转详情
  goSnapshotDetail = (data, type) => {
    let { tab, location } = this.props;
    let { snapshots, vehicleData } = this.state;
    const searchData = {
      startTime: moment()
        .subtract(3, "d")
        .valueOf(),
      endTime: moment().valueOf(),
      timerTabsActive: 3
    };
    window.LM_DB.add("parameter", {
      id: data.id.toString(),
      data,
      type,
      vehicleData,
      searchData,
      list: snapshots
    }).then(function() {
      tab.closeTabForName("objSnapshotDetail");
      tab.goPage({
        moduleName: "objSnapshotDetail",
        location,
        data: {
          id: data.id
        }
      });
    });
  };
  render() {
    let { total, vehicleData, snapshots, loading,searchData } = this.state;
    const operations = (
      <div className="header-little-pagtion">
        <div className="header-info">
          共显示<span className="num"> {total} </span>条数据
        </div>
      </div>
    );
    return (
      <Wrapper
        className="vehicle-file-snapshot"
        title="抓拍记录"
        renderLeft={leftList(vehicleData)}
        extra={operations}
      >
        <RightList
          dataList={snapshots}
          searchData={searchData}
          initSnapshot={this.initSnapshot}
          goSnapshotDetail={this.goSnapshotDetail}
          loading={loading}
        />
      </Wrapper>
    );
  }
}
export default VehicleFileSnapshot;
