import React, { Component } from "react";

const Loading = Loader.Loading;
const NoData = Loader.loadBaseComponent("NoData");
const Card = Loader.loadBusinessComponent("BaseLibComponents", "List");
const InfiniteScrollLayout = Loader.loadBaseComponent("ReactVirtualized", 'InfiniteScrollLayout');

class VehicleSnapshotRecord extends Component {
  constructor(props) {
    super(props);
    this.infinitRef = React.createRef();
  }
  componentWillReceiveProps(nextProps){
    if(nextProps.dataList !== this.props.dataList){
      this.infinitRef && this.infinitRef.current && this.infinitRef.current.forceUpdateGrid();
    }
  }

  loadMore = () => {
    let { searchData, initSnapshot, total } = this.props;
    this.setState({
      hasLoadMore:false
    }, () => {
      if (searchData.offset > total - searchData.limit) {
        return;
      }
      searchData.offset += searchData.limit;
      initSnapshot && initSnapshot(searchData).then(length => {
        if(length === searchData.limit){
          this.setState({ hasLoadMore:true })
        }
      })
    })
   };
  render() {
    let {
      dataList,
      loading,
      hasLoadMore,
      loadMore,
      goSnapshotDetail
    } = this.props;
    return (
      <div className="vehicle-snapshot-wrapper">
        {!!dataList.length ? (
          <InfiniteScrollLayout
            itemWidth={176}
            itemHeight={276}
            pdWidth={140}
            data={dataList}
            hasLoadMore={hasLoadMore}
            hasBackTop={true}
            ref={this.infinitRef}
            loadMore={loadMore}
            renderItem={item => (
              <Card
                key={item.id}
                data={item}
                hidePlante={true}
                type= "vehicle"
                entry='records'
                goSnapshotDetail={goSnapshotDetail}
                accessType={item.accessType}
                isNonMotorized={false}
                personMap={false}
                hasDetail={false}
              />
            )}
          />
        ) : loading ? (
          <Loading loading={loading} />
        ) : (
          <NoData />
        )}
      </div>
    );
  }
}

export default React.forwardRef((props, ref) => (
  <VehicleSnapshotRecord {...props} forwardRef={ref} />
));
