import React from "react";
import { Divider } from "antd";
import "./index.less";

const ImageView = Loader.loadBaseComponent("ImageView");
const IconFont = Loader.loadBaseComponent("IconFont");

export default data => {
  let { vehiclePlateNum, deviceName, recentAppearedUrl, recentTime } = data;
  return (
    <div className="vehicle-card">
      <div className="img-box">
        <ImageView src={recentAppearedUrl} />
      </div>
      <div className="info">
        <p className="title">
          <IconFont type="icon-S_Bar_Brand" />
          {vehiclePlateNum || "暂无"}
        </p>
        <Divider
          style={{ backgroundColor: "var(--bd-light)", margin: "4px 0" }}
        />
        <p className="title-sub">最近出现：</p>
        <p>
          <IconFont type="icon-S_Tree_Add" />
          {(deviceName && Utils.getSubStr(deviceName)) || "暂无"}
        </p>
        <p>
          <IconFont type="icon-S_Edit_ClockStart" />
          {Utils.formatTimeStamp(recentTime) || "暂无"}
        </p>
      </div>
    </div>
  );
};
